
<template>
  <nav id="navHome" style="display:none;">    
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> |
    <router-link to="/BolsaTrabajo">Bolsa Trabajo</router-link>
    <router-link to="/EducacionFinanciera">Educación financiera</router-link>
    <router-link to="/BuroEntidadesFinancieras">Buro entidades Financieras</router-link>

  </nav>
  <router-view/>
<HelloWorld msg=""></HelloWorld>
<nav class="shadow navbar navbar-expand-lg a-nav bg-white fixed-top" aria-label=" ">
    <div class="container-xl">
      <a class="navbar-brand a-nav me-5" href="#">
            <img src="../assets/images/logo_c.png" class="zoom img-fluid " alt="logo" style="position: inherit; width: 75px;">
          </a>
       <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class=""><h3><i class="bi bi-list"></i></h3>
    </span>
    </button>

      <div class="row collapse navbar-collapse ms-5" id="navbarSupportedContent">
        <ul class="navbar-nav mb-2">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#featured-4">Quienés Somos</a>
          </li>        
          <li class="nav-item mx-5">
            <a class="nav-link active" aria-current="page" href="#featured-5">Productos</a>
          </li>  
          <li @click="SaveConducef(0)" class="nav-item mx-5">
            <router-link class="nav-link active" to="/EducacionFinanciera">Educación Financiera</router-link>
            <!--<a class="nav-link active" aria-current="page" href="/EducacionFinanciera"></a>-->
          </li>
          <li class="nav-item dropdown mx-5">
            <a class="nav-link dropdown-toggle" href="#" id="dropdown07XL" data-bs-toggle="dropdown" aria-expanded="false">Clientas</a>
            <ul class="dropdown-menu" aria-labelledby="dropdown07XL">
              <li><a class="dropdown-item" href="#estadoCuenta">Estado de cuenta</a></li>
              <li><a class="dropdown-item" href="#formulario-soli">Solicitar</a></li>
            </ul>
          </li>
          <li class="nav-item mx-5">
            <a class="nav-link active" aria-current="page" href="#contacto">Contacto</a>
          </li>
        </ul>
      </div>
      
    </div>
  </nav>
  <router-view/>
<div id="redes_sociales" class="icon-bar">
  <a href="https://www.facebook.com/CredicerParaLaMujer/" class="facebook"><i class="fa fa-facebook"></i></a> 
  <a href="https://twitter.com/Crediceroficial" class="twitter"><i class="fa fa-twitter"></i></a> 
  <a href="https://www.computrabajo.com.mx/credicer" class="google" style="text-decoration: none;" target="_blank"><strong style="color: #FFF !important;">CT</strong></a> 
  <a href="https://www.linkedin.com/in/credicer-servicios-financieros-3b0b0491/?originalSubdomain=ar" class="linkedin"><i class="fa fa-linkedin"></i></a>
</div>
    <!---<div class="row" style="background-color: #BA3171; padding-top: 1rem!important; padding-bottom: 1rem!important;">
    <div class="col-xxl-12">
      <div class="container">
        <marquee class="text-light">Recuerda que en Credicer, estaremos operando con normalidad para la solicitud de tu préstamo. Es importante seguir las recomendaciones de higiene que hemos anunciado por redes sociales y en cada punto de venta.
  Si tienes dudas o requieres apoyo para solicitar tu préstamo, pide ayuda vía telefónica o por redes sociales, tenemos un equipo preparado para atenderte.
  </marquee>
      </div>
    </div>
  </div>-->


  
    
  <div class="container col-xxl-8 px-4 col-sm-12 mt-5">
    <div id="presentacion" class="row flex-lg-row-reverse align-items-center g-5 ">
      <div class="col-md-10 col-sm-8 mx-auto col-lg-4">
        <img src="../assets/images/Clarita.png" class="zoom mt-5 d-block mx-lg-auto img-fluid img-responsive" alt="" width="300" height="500" loading="lazy">
      </div>
      <div class="col-lg-8 col-md-8 col-sm-12 text-end">
        <h1 class="fw-bold lh-1 mb-3 ">credicer financiera<br>para la mujer</h1>
        <p class="p-hero fw-light " style="font-family: 'GothamLight';color: black;">Apoyamos a la mujer mexicana para que crezca con su entorno al proporcionarle préstamos personales de fácil acceso y trabajo digno sin necesitar de una carrera profesional</p>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <a id="featured-4" type="button" class="btn btn-solid btn-lg px-4 me-md-2" href="#formulario-soli">Solicitar préstamo</a>
        </div>
      </div>
    </div>
  </div>
  
  <div class="container pt-4 px-4 " >
    <div class="row g-4 py-4 row-cols-1 row-cols-lg-4">
      <div class="feature col">
        <h2>quiénes<br>somos</h2>
      </div>
      <div class="zoom feature col">
        <div class="feature-icon">
          <img src="../assets/images/book.svg" class="d-block ms-lg-auto img-fluid" alt="" height="50" loading="lazy">
        </div>
        <h3>filosofía</h3>
        <p class="p-hero text-end" style="font-family: 'GothamLight';color: black;">Apoyamos a la mujer mexicana para que crezca con su entorno al proporcionarle préstamos personales de fácil acceso y trabajo digno sin necesitar de una carrera profesional, así como mediante nuestro programa de responsabilidad <b>CRECER DE LA MANO</b>.</p>
      </div>
      <div class="zoom feature col">
        <div class="feature-icon">
          <img src="../assets/images/hand.svg" class="d-block ms-lg-auto img-fluid" alt="" height="50" loading="lazy">
        </div>
        <h3>misión</h3>
        <p class="p-hero text-end" style="font-family: 'GothamLight';color: black;">Ser una empresa reconocida por su acceso sencillo a sus servicios, su honorabilidad y confiabilidad.</p>
      </div>
      <div class="zoom feature col">
        <div class="feature-icon">
          <img src="../assets/images/list.svg" class="d-block ms-lg-auto img-fluid" alt="" height="50" loading="lazy">
        </div>
        <h3>visión</h3>
        <p class="p-hero text-end" style="font-family: 'GothamLight';color: black;">Proveer préstamos personales que permitan ser un apoyo confiable y seguro para las amas de casa, y así contribuir al bienestar familiar, cimentados en soluciones alcanzables.</p>
      </div>
    </div>
  </div>
  <div class=" container px-4" id="featured-10">
    <div class="row g-4 py-1 row-cols-1 row-cols-lg-4">
      <div class="col-md-10 p-lg-5 mx-auto my-5">
          <h2 class="display-4 fw-normal">responsabilidad<br>social</h2>
          <p class="p-hero text-start lead fw-normal" style="font-family: 'GothamLight';color: black;">Nuestra empresa ofrece préstamos con valor social agregado a la mujer mexicana de forma rápida y fácil acceso. Además, se encuentra comprometida con el mejoramiento de la calidad de vida de las comunidades y nos interesa generar una buena relación entre los clientes, colaboradores y entorno social, tomando en cuenta siempre la honorabilidad y confiabilidad de nuestras acciones.</p>
        </div>
        <div class="col-md-2 col-sm-12 p-lg-5  my-5">
          <img src="../assets/images/iso_color.png" class="zoom d-block ms-lg-auto img-fluid" alt="" height="170" loading="lazy">
        </div>
    </div>
  </div>
  <div class="container px-4 pt-5" id="featured-5">
    <div class="row g-4 py-5 row-cols-1 row-cols-lg-4">
      <div class="feature row col-md-12">
        <h2 class="col-md-4 mx-aut">nuestros<br>productos</h2>
      </div>
      <div class="zoom col-md-6 col-sm-12 col-lg-4 mt-5 button-container">
            <div class="card vCard-t p-1" style="border: none;">
               <div class=" div-card-prod">
                  <br/>
                  <br/>
                  <h1 class="ms-5 text-card-prod">12 Semanas</h1>
                  <h1 class="ms-5 text-card-sub mt-4">Único</h1>
                  <h1 class="ms-5 text-card-parra">Para comisionistas</h1>
                  <h1 class="ms-5 text-card-sub mt-4">Semanal</h1>
                  <h1 class="ms-5 text-card-parra">Forma de pago</h1>
                  <h1 class="ms-5 text-card-sub mt-4">No aplica</h1>
                  <h1 class="ms-5 text-card-parra">Comisión por  apertura</h1>
                  <h1 class="ms-5 text-card-sub mt-4">85.71%</h1>
                  <h1 class="ms-5 text-card-parra">Tasa anual fija</h1>
                  <h1 class="ms-5 text-card-sub mt-4">347.3%</h1>
                  <h1 class="ms-5 text-card-parra">CAT Promedio sin IVA</h1>
                  <h1 class="ms-5 text-card-parra">Para fines informativos</h1>
                  <h1 class="ms-5 text-card-parra">y de comparación *</h1>
                  <h1 class="ms-5 text-card-sub mt-4">30/10/2023</h1>                  
                  <h1 class="ms-5 mb-5 text-card-parra">Fecha de cálculo</h1>
                  
                  <a type="button" class="btn btn-solid  px-4 me-md-2 " href="#formulario-soli">Solicitar préstamo</a>
                  <h1></h1>
               </div>
            </div>
      </div>
      <div class="zoom col-md-6 col-sm-12 col-lg-4 mt-5 button-container">
            <div class="card vCard-t p-1" style="border: none;">
               <div class=" div-card-prod2">
                  <br/>
                  <br/>
                  <h1 class="ms-5 text-card-prod-14">14 Semanas</h1>
                  <h1 class="ms-5 text-card-sub mt-4">$3,000.00</h1>
                  <h1 class="ms-5 text-card-parra">Monto en tu primer préstamo</h1>
                  <h1 class="ms-5 text-card-sub mt-4">Semanal</h1>
                  <h1 class="ms-5 text-card-parra">Forma de pago</h1>
                  <h1 class="ms-5 text-card-sub mt-4">5% sobre el</h1>
                  <h1 class="ms-5 text-card-sub">monto del crédito</h1>
                  <h1 class="ms-5 text-card-parra">Comisión por  apertura</h1>
                  <h1 class="ms-5 text-card-sub mt-4">126.67%</h1>
                  <h1 class="ms-5 text-card-parra">Tasa anual fija</h1>
                  <h1 class="ms-5 text-card-sub mt-4">958.4%</h1>
                  <h1 class="ms-5 text-card-parra">CAT Promedio sin IVA</h1>
                  <h1 class="ms-5 text-card-parra">Para fines informativos</h1>
                  <h1 class="ms-5 text-card-parra">y de comparación *</h1>
                  <h1 class="ms-5 text-card-sub mt-4">07/05/2024</h1>
                  <h1 class="ms-5 text-card-parra mb-4">Fecha de cálculo</h1>
                  <a type="button" class="btn btn-solid  px-4 me-md-2 " href="#formulario-soli">Solicitar préstamo</a>
               </div>
            </div>
      </div>
      <div class="zoom col-md-6 col-sm-12 col-lg-4 mt-5 button-container">
            <div class="card vCard-t p-1" style="border: none;">
               <div class=" div-card-prod3">
                  <br/>
                  <br/>
                  <h1 class="ms-5 text-card-prod">26 Semanas</h1>
                  <h1 class="ms-5 text-card-sub mt-4">$20,000 a $30,000</h1>
                  <h1 class="ms-5 text-card-parra">Monto de préstamo</h1>
                  <h1 class="ms-5 text-card-sub mt-4">Semanal</h1>
                  <h1 class="ms-5 text-card-parra">Forma de pago</h1>
                  <h1 class="ms-5 text-card-sub mt-4">5% sobre el</h1>
                  <h1 class="ms-5 text-card-sub">monto del crédito</h1>
                  <!--<h1 class="ms-5 text-card-sub">monto del crédito</h1>-->
                  <h1 class="ms-5 text-card-parra">Comisión por  apertura</h1>
                  <h1 class="ms-5 text-card-sub mt-4">89.01%</h1>
                  <h1 class="ms-5 text-card-parra">Tasa anual fija</h1>
                  <h1 class="ms-5 text-card-sub mt-4">426.1%</h1>
                  <h1 class="ms-5 text-card-parra">CAT Promedio sin IVA</h1>
                  <h1 class="ms-5 text-card-parra">Para fines informativos</h1>
                  <h1 class="ms-5 text-card-parra">y de comparación *</h1>
                  <h1 class="ms-5 text-card-sub mt-4">22/10/2024</h1>
                  <h1 class="ms-5 text-card-parra mb-4">Fecha de cálculo</h1>
                  <a type="button" class="btn btn-solid  px-4 me-md-2 " href="#formulario-soli">Solicitar préstamo</a>
               </div>
            </div>
      </div>
      <div class="col-md-6 col-sm-12 col-lg-12">
        <div class="zoom col-md-6 col-sm-12 col-lg-4 mt-5 button-container">
            <div class="card vCard-t p-1" style="border: none;">
               <div class=" div-card-prod4">
                  <br/>
                  <br/>
                  <h1 class="ms-5 text-card-prod">22 Semanas</h1>
                  <h1 class="ms-5 text-card-sub mt-4">$6,000</h1>
                  <h1 class="ms-5 text-card-parra">Monto en tu primer préstamo</h1>
                  <h1 class="ms-5 text-card-sub mt-4">Semanal</h1>
                  <h1 class="ms-5 text-card-parra">Forma de pago</h1>
                  <h1 class="ms-5 text-card-sub mt-4">No Aplica</h1>
                  <!--<h1 class="ms-5 text-card-sub">monto del crédito</h1>-->
                  <h1 class="ms-5 text-card-parra">Comisión por  apertura</h1>
                  <h1 class="ms-5 text-card-sub mt-4">147.25%</h1>
                  <h1 class="ms-5 text-card-parra">Tasa anual fija</h1>
                  <h1 class="ms-5 text-card-sub mt-4">835.6%</h1>
                  <h1 class="ms-5 text-card-parra">CAT Promedio sin IVA</h1>
                  <h1 class="ms-5 text-card-parra">Para fines informativos</h1>
                  <h1 class="ms-5 text-card-parra">y de comparación *</h1>
                  <h1 class="ms-5 text-card-sub mt-4">26/03/2024</h1>
                  <h1 class="ms-5 text-card-parra mb-4">Fecha de cálculo</h1>
                  <a type="button" class="btn btn-solid  px-4 me-md-2 " href="#formulario-soli">Solicitar préstamo</a>
               </div>
            </div>
        </div>
        <div class="zoom col-md-6 col-sm-12 col-lg-4 mt-5 button-container">
              <div class="card vCard-t p-1" style="border: none;">
                <div class=" div-card-prod5">
                    <br/>
                    <br/>
                    <h1 class="ms-5 text-card-prod">52 Semanas</h1>
                    <h1 class="ms-5 text-card-sub mt-4">$20,000</h1>
                    <h1 class="ms-5 text-card-parra">Monto en tu primer préstamo</h1>
                    <h1 class="ms-5 text-card-sub mt-4">Semanal</h1>
                    <h1 class="ms-5 text-card-parra">Forma de pago</h1>
                    <h1 class="ms-5 text-card-sub mt-4">5% sobre el</h1>
                    <h1 class="ms-5 text-card-sub">monto del crédito</h1>
                    <!--<h1 class="ms-5 text-card-sub">monto del crédito</h1>-->
                    <h1 class="ms-5 text-card-parra">Comisión por  apertura</h1>
                    <h1 class="ms-5 text-card-sub mt-4">40.7%</h1>
                    <h1 class="ms-5 text-card-parra">Tasa anual fija</h1>
                    <h1 class="ms-5 text-card-sub mt-4">137.7%</h1>
                    <h1 class="ms-5 text-card-parra">CAT Promedio sin IVA</h1>
                    <h1 class="ms-5 text-card-parra">Para fines informativos</h1>
                    <h1 class="ms-5 text-card-parra">y de comparación *</h1>
                    <h1 class="ms-5 text-card-sub mt-4">09/11/2023</h1>
                    <h1 class="ms-5 text-card-parra mb-4">Fecha de cálculo</h1>
                    <a type="button" class="btn btn-solid  px-4 me-md-2 " href="#formulario-soli">Solicitar préstamo</a>
                </div>
              </div>
        </div>
      </div>
      <!--<div class="zoom col-md-6 col-sm-12 col-lg-4 mt-5 button-container">
            <img src="../assets/images/credicer-12ss.png" class="d-block ms-lg-auto img-fluid" alt="" height="50" loading="lazy"/>
            <span></span>
            <a type="button" class="btn btn-solid  px-4 me-md-2 " href="#formulario-soli">Solicitar préstamo</a>
      </div>
      <div class="zoom col-md-6 col-sm-12 col-lg-4 button-container">
            <img src="../assets/images/credicer-14ss.png" class="d-block ms-lg-auto img-fluid" alt="" height="50" loading="lazy"/>
            <a type="button" class="btn btn-solid  px-4 me-md-2 " href="#formulario-soli">Solicitar préstamo</a>
      </div>
      <div class="zoom col-md-6 col-sm-12 col-lg-4 mt-5 button-container">
            <img src="../assets/images/credicer-26ss.png" class="d-block ms-lg-auto img-fluid" alt="" height="50" loading="lazy"/>
            <a type="button" class="btn btn-solid  px-4 me-md-2 " href="#formulario-soli">Solicitar préstamo</a>
      </div>-->
      <div class="col-md-12 col-lg-12 col-sm-12 bg-white p-2" style="border-radius: 5%;">
          <h5 class="text-card-parra">*	Contratar créditos por arriba de tu capacidad de pago puede afectar tu historial crediticio. Incumplir tus obligaciones te puede generar comisiones e intereses moratorios. El avalista, obligado solidario o coacreditado responderá como obligación principal por el total frente a la entidad. </h5>
      </div>
    </div>
  </div>


  <div class="row-time-line " >
    <div class="">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 253" preserveAspectRatio="none">
          <path class="elementor-shape-fill" fill="#D33380" d="M0,218c0,0,151-110,279-124s165,101,390,76S999,2,1161,0s280,160,352,156
  s112.3-89,212-92s195,82,195,82v107H0V218z"></path>
      </svg>
    </div>
   <div  class="col-md-12 col-sm-12 col-lg-12 bg-section">   
     <div  class="ps-5"><h2 class="proceso-prestamo">proceso de<br> préstamo</h2></div>
<section  class="timeline mx-auto  col-md-12 col-sm-12 col-lg-12">
  <ol>
     <li>
      
      <div class="final-div-ss">
        <time></time> 
      </div>
    </li>
    <li>
      <div >
        <time><h2 style="color:white; font-size: 1.5em;">llena tu solicitud en línea</h2></time> <p class="font-weight-light">
          Una vez recibida su solicitud se canaliza al punto de venta más cercano a su domicilio (tiempo de respuesta
         24 a 48 hrs. máximo)
        </p>
      </div>
    </li>
     <li>
      <div >
         <time><h2 style="color:white; font-size: 1.5em;">entrega tus documentos</h2></time> <p class="font-weight-light">
          Del solicitante y el aval: <br>
          Copia de INE<br>
          Copia de CURP<br>
          Comprobante de domicilio no mayor a 3 meses<br>
          Enunciar 3 garantías prendarias (tv, microondas, teatro en casa, etc...)
        </p>
      </div>
    </li>
    

    <li>
      <div>
         <time><h2 style="color:white; font-size: 1.5em;">proceso de supervisión</h2></time> <p class="font-weight-light">
          Una supervisora acudirá a tu domicilio para verificar la documentación y validar las garantías prendarias (tv, microondas, teatro en casa, etc...)
        </p>
      </div>
    </li>
   
    
    <li>
      <div>
        <time><h2 style="color:white; font-size: 1.5em;">recibe tu préstamo</h2></time> <p class="font-weight-light">
          Se te indicará la hora y el día en que deberás asistir al punto de venta que te corresponde para que recibas tu préstamo.
        </p>
      </div>
    </li>

     <li>
      <div>
         <time><h2 style="color:white; font-size: 1.5em;">realiza tus pagos</h2></time> <p class="font-weight-light">
          Evita multas y pagos adicionales, lleva tus pagos en el día y horario establecidos.
        </p>
      </div>
    </li>
    
   
   
    
    <li></li>
  </ol>
 
</section>
         
  </div>
    <div class="">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 253" preserveAspectRatio="none">
          <path class="elementor-shape-fill" fill="#D33380" transform="scale(1, -1)" transform-origin="center" d="M0,218c0,0,151-110,279-124s165,101,390,76S999,2,1161,0s280,160,352,156
            s112.3-89,212-92s195,82,195,82v107H0V218z"></path>
      </svg>
    </div>

  </div>
  <div id="formulario-soli">

  </div>
  <!-----------------------Formulario de solicitud de préstamos----------------------->
  <FormularioSolicitud ref="HomeView"/>
 <!---------------------------------------------------------------------------------------->
 <div id="estadoCuenta"></div>
   <div  class="row bg-section-crediapp col-md-12 col-sm-12 col-lg-12  py-3">    
      <div class="pb-2 col-md-5 col-sm-12 col-lg-4 ms-auto my-auto">
        <img src="../assets/images/img_char_edocta.png" class="d-block mx-lg-auto img-fluid img-responsive" alt="" width="300" height="500" loading="lazy">
      </div>
      <div class="me-auto col-md-3 col-sm-12 col-lg-4">
          <h2 style="color:white;" class="display-4 py-4 fw-normal pe-2">estado<br>de cuenta</h2>
          <p style="font-family: 'GothamLight';color: white;" class="p-hero text-end lead fw-normal pe-2">Si ya formas parte de nuestra familia Credicer, puedes consultar tu contrato y estado de cuenta.
          </p>
          <div class="d-grid gap-2 d-md-flex justify-content-md-end my-5">
          <button style="width:200px;" type="button" class="btn-new-a btn-color btn btn-solid btn-lg mx-auto me-md-2" @click="ModalEdoCuenta()">Consultar</button>
        </div>
      </div>   
         
  </div>

  <div class="container px-4 pt-5 bg-light" id="featured-5">
    <div class="row g-4 py-5 row-cols-1 ">
      <div class="col-md-12 text-center">
        <h2 class="col-md-8 mx-auto text-center">bolsa de trabajo</h2>
      </div>

      <div class="col-md-6 col-sm-12 col-lg-4 mt-5 mx-auto">
            <router-link class="btn btn-lg btn-solid  px-4 me-md-2 " to="/BolsaTrabajo" style="font-size: 1em;"><h4 style="color:aliceblue;"><i class="bi bi-arrow-bar-right"></i>Registro</h4></router-link>
            <!--<a type="button" class="btn btn-lg btn-solid  px-4 me-md-2 " href="/BolsaTrabajo" style="font-size: 1em;"><h4 style="color:aliceblue;"><i class="bi bi-arrow-bar-right"></i>Registro</h4></a>-->
      </div>
      
    </div>
  </div>

   <div class="bg-white py-5" id="contacto" style="padding-bottom: 0px !important;">
        <div class="container">
            <div class="row">
                <div style="text-align: center;" class="col-md-6 col-sm-12 col-lg-6  text-lg-left">
                    <p style="color:rgba(0,0,0,0.3);" class="mb-2 "> <span class="fa fa-map-marker-alt mr-2"></span> Puerto de Cartagena #215, Col. Arbide, León Gto, C.P. 37360</p>
                    <div class="col-md-6 col-sm-12 col-lg-6 d-block d-sm-inline-block">
                        <p class="mb-2">
                            <i class="bi bi-envelope"></i> <a class="mr-4 font-weight-light" href="mailto:support@mobileapp.com">contacto@credicer.mx</a>
                        </p>
                    </div>
                    <div class="col-md-6 col-sm-12 col-lg-6 d-block d-sm-inline-block">
                        <p class="mb-2">
                            <i class="bi bi-headset"></i> <a class="font-weight-light" href="tel:51836362800">01800-841-70-70</a>
                        </p>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12 col-lg-6">
                     <div class="social-icons ms-auto">

                        <a href="https://www.facebook.com/CredicerParaLaMujer/" target="_blank"><span class="fa fa-facebook"></span></a>

                        <a href="https://twitter.com/Crediceroficial" target="_blank"><span class="fa fa-twitter"></span></a>

                        <a href="https://www.computrabajo.com.mx/credicer" target="_blank"><strong style="color: #f4c9e2 !important;">CT</strong></a>

                        <a href="https://www.linkedin.com/in/credicer-servicios-financieros-3b0b0491/?originalSubdomain=ar" target="_blank"><span class="fa fa-linkedin"></span></a>

                    </div>
                </div>
            </div>
        </div>
    </div>
   


  <div class="pt-5 pb-3 bg-white">
    <div class="col-md-12 col-sm-12 col-lg-12">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-sm-6 pt-sm-3 text-center">
            <a @click="SaveConducef(1)" style="cursor: pointer;">
            <img src="../assets/images/educa-cartera.png"/>
            </a>
            <a @click="SaveConducef(1)" style="cursor: pointer;">
            <img src="../assets/images/c1.png" style="width: 115px;"/>
            </a>
          </div>
          <div class="col-lg-3 col-sm-6 pt-sm-3 text-center">
            <a href="https://www.condusef.gob.mx/" target="_blank">
            <img src="../assets/images/condusef.png"/>
            </a>
          </div>
          <div class="col-lg-3 col-sm-6 pt-sm-3 text-center">
            <a href="https://www.gob.mx/cnbv" target="_blank">
              <img src="../assets/images/cnbv.png"/>
              </a>
          </div>
          
          <div class="col-lg-3 col-sm-6 pt-sm-3 text-center">
            <a href="https://www.buro.gob.mx/general_gob.php?id_sector=69&id_periodo=34" target="_blank">
            <img src="../assets/images/buro.png"/>
          </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="pt-5 pb-3 bg-white ">
    <div class="col-md-12 col-sm-12 col-lg-12">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3 col-sm-12 text-end text-center">
            <a class="font-weight-light" href="/Terminos/contrato.html" target="_blank">Términos y Condiciones</a>
          </div>
          <div class="col-md-3 col-lg-3 col-sm-12 text-center text-center">
            <a class="font-weight-light" href="/Terminos/marco.html" target="_blank">Marco Jurídico</a>
          </div>
          <div class="col-md-3 col-lg-3 col-sm-12 text-start text-center">
            <a class="font-weight-light" href="Terminos/privacidadcandidatos.html" target="_blank">Aviso de privacidad</a>
          </div>
          <div class="col-md-3 col-lg-3 col-sm-12 text-start text-center">
            <router-link class="font-weight-light" to="/BuroEntidadesFinancieras">Buró de Entidades Financieras</router-link>
            
          </div>
          <div class="col-lg-12 text-center pt-3" style="font-size: small;">
            <p class="font-weight-light">El buró de Entidades Financieras contiene información de CRECER SOLUCION INMEDIATA SAPI de CV SOFOM ENR sobre nuestro desempeño frente a los Usuarios, por la prestación de productos y servicios. Te invitamos a consultarlo en la página
            <a class="font-weight-light" href="http://www.buro.gob.mx" target="_blank">http://www.buro.gob.mx; https://www.buro.gob.mx/general_gob.php?id_sector=69&id_periodo=25</a>
            o en nuestra página de internet
            <a class="font-weight-light" href="https://www.credicer.mx/" target="_blank">https://www.credicer.mx/</a>
            </p>
          </div>
          <div class="col-lg-12 text-center pt-3" style="font-size: small;">
            <p class="font-weight-light">Credicer para su constitución y operación como sociedad financiera de objeto múltiple, entidad no regulada, no requiere de la autorización de la Secretaría de Hacienda y Crédito Público, y está sujeta a la supervisión de la Comisión Nacional Bancaria y de Valores, únicamente para efectos de lo dispuesto por el artículo 56 de la Ley General de Organizaciones y Actividades Auxiliares del Crédito.</p>
          </div>
          <div class="col-lg-12 text-center pt-3 row" style="font-size: small;">
            <p class="font-weight-light">Para consulta de nuestras tasas y comisiones, te invitamos a visitar la página: 
            <a class="font-weight-light col-lg-4" href="https://phpapps.condusef.gob.mx/reco/index.php" target="_blank">https://phpapps.condusef.gob.mx/reco/index.php </a>
            o
            <a class="font-weight-light col-lg-4" href="http://ifit.condusef.gob.mx/ifit/ftb_vista_general.php" target="_blank">http://ifit.condusef.gob.mx/ifit/ftb_vista_general.php</a>
          </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="" style="height: 70px; background-color: #D33380;">
    <div class="col-lg-12">
      <div class="container">
        <div class="col-lg-12 text-center" style="font-size: small;">
        <p class="text-white font-weight-light pt-4">COPYRIGHT © 2020. TODOS LOS DERECHOS RESERVADOS CREDICER</p>
        </div>
      </div>
    </div>
  </div>
  

<!-- Button trigger modal -->
<button style="display: none;" id="modal_plan23" type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#myModal45">
            modal val
</button>

<!-- Modal -->
<div class="modal fade text-start" id="myModal45" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Estado de cuenta</h5>
        <button id="cerrarRequisitos" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body text-center">
         <p>El servicio estará disponible muy pronto...</p><br>
         <img src="../assets/images/motion.gif"/>
      </div>
      <div class="modal-footer">
        <button id="cancelarEnvio" type="button" class="btn btn-primary" data-bs-dismiss="modal">Aceptar</button>
      </div>
    </div>
  </div>
</div>

</template>

<script>

// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue';
import FormularioSolicitud from '@/components/FormularioSolicitud.vue';
import axios from "axios";

const $ = require('jquery')
// Lo declaramos globalmente
window.$ = $

export default {
  name: 'HomeView',
  components: {
    HelloWorld,
    FormularioSolicitud
},
  data() {
    return{
      uri:'https://credicer.mx/api',
      contador : 0, 
      intervalo: null,
      runing: false,
      btnmsj: 'Detener',
      info: '',
      isLoading: false,
      ip:"",
      estado:"",
      municipio:"",
      latitud:"",
      longitud:"",
      obj:null,
      infoGetIp:null,
      seccion: 'Educa tu cartera'
      //tipoLoading: 'dots'
    }
  },
  mounted(){
    this.GetData();
    this.GetDatosIniciales();
  },
  methods: {
    GetData(){
      this.isLoading = true;
      axios
      .get(this.uri+'/pais')
      .then(response => {
        this.info = response
        console.log(response)
      })
      .catch(error => {
        console.log(error)
        this.errored = true
      })
      .finally(() => this.isLoading = false)
  
    },
    GetDatosIniciales(){
      this.isLoading = true;
      axios
      .get('https://geolocation-db.com/json/7bad3e80-c704-11eb-a4bc-19a5c6a04c5d')
      .then(response => {
        this.infoGetIp = response
        console.log("Data: "+response.data+"\n"+response.status)
        //let objTemp = JSON.parse(response.data);
        
        
            this.ip = response.data.IPv4;
            this.estado = response.data.state;
            this.municipio = response.data.city;
            this.latitud = response.data.latitude;
            this.longitud = response.data.longitude;
        
        //alert(JSON.stringify(this.obj, null, 5))
        console.log(response)
      })
      .catch(error => {
        console.log(error)
        this.errored = true
      })
      .finally(() => this.isLoading = false)
  
    },
    SaveConducef(val){
          
                                    
        if(this.ip !== null){
                //formato del formdata
            var formDataSC = new FormData();
            var vSeccion = val == 1 ?  'Educa tu cartera' : 'Educación Financiera';
            formDataSC.append('seccion', vSeccion);
            formDataSC.append('ip', this.ip);
            formDataSC.append('estado', this.estado);
            formDataSC.append('municipio', this.municipio);
            formDataSC.append('latitud', this.latitud);
            formDataSC.append('longitud', this.longitud);


            //peticion por axios
            axios(            
                { 
                  method:"post",
                  url:this.uri+'/seccion/conducef',
                  data: formDataSC,
                  //pasamos los encabezados del post request 
                  headers: {
                  'content-type': 'application/x-www-form-urlencoded'
                  }
                })
            .then(response => {
              if(response.status == 200){
                if(val == 1){
                  window.open('https://webappsos.condusef.gob.mx/EducaTuCartera/');
                }
                console.log(response.data);
              }else{
                this.message = "Aviso: ¡Error en el servidor!";
                this.toast('danger');
              }
              
              
            })
            .catch(error => {
              console.log(error)
              this.errored = true
            })
            .finally(() => this.isLoading = false);

          
        }else{
           location.href = "#";
        }
          
         
    },
    ModalEdoCuenta(){
       $("#modal_plan23").trigger("click");
      console.log('mostrar modal')
    }
  }
}


</script>